<template>
  <van-cell-group>
    <van-cell v-for="(item, index) in list" :key="index">
      <template #title>
        <div class="f-cb fw-b">
          <van-checkbox
            v-model="item.checked"
            @change="toggleAll($event, index)"
          >
            {{ item.nodeName }}
          </van-checkbox>
          <van-badge
            :content="item.additionalCount"
            max="99"
            @click="toDetail(index)"
          />
        </div>
      </template>
      <template #right-icon>
        <van-icon name="arrow" size="20" @click="toDetail(index)" />
      </template>
      <template #label>
        <van-checkbox-group
          v-model="item.result"
          ref="checkboxGroup"
          direction="horizontal"
        >
          <van-checkbox
            v-for="(vm, idx) in item.vms"
            :key="idx"
            :name="idx"
            shape="square"
            class="m-t-10"
            @click="onClick(index)"
          >
            {{ vm.deviceCode }}
          </van-checkbox>
        </van-checkbox-group>
      </template>
    </van-cell>
  </van-cell-group>
</template>
<script>
export default {
  data() {
    return {
      list: [],
    }
  },
  created() {
    this.listVmChannelTradeAdditionalLogVm()
  },
  methods: {
    async listVmChannelTradeAdditionalLogVm() {
      const { data } = await this.$api.listVmChannelTradeAdditionalLogVm()
      console.log('listVmChannelTradeAdditionalLogVm===>', data)
      this.list = data.map((v) => ({ ...v, result: [], checked: false }))
    },
    toggleAll(val, index) {
      // console.log(val, index)
      this.$refs.checkboxGroup[index].toggleAll(val)
    },
    onClick(index) {
      // console.log(index)
      const { vms, result } = this.list[index]
      this.list[index].checked = vms.length === result.length
    },
    toDetail(index) {
      // console.log(index)
      const { vms, result } = this.list[index]
      if (!result.length) return this.$toast(this.$t('tips[2]'))
      const vmCodes = result.map((i) => vms[i].vmCode)
      sessionStorage.setItem('vmCodes', JSON.stringify(vmCodes))
      this.$router.push({ name: 'TradeDetail' })
    },
  },
}
</script>